<template>
  <div id="results"  class="container">

     <div class="row results">
      <div class="col-md-1"></div>
      <div class="col-md-5 finalcalculation" id="final_calculation">
        <h2 id="final_calculation_value">{{ final_calculation }}%</h2>
        <p>Risk of outpatient AKI over the next 18 months</p>
      </div>
      <div class="col-md-5">
        <p>
          In our publication in Kidney Medicine, we proposed two tentative thresholds, above which patients or research subjects may be considered high risk for outpatient AKI over the subsequent 18 months, based on semi-arbitrarily chosen weights assigned to the utility of true positive cases, the utility of true negative cases, the cost of false positive cases, and the cost of false negative cases.</p>
          <p> One threshold, derived for clinical practice, defined high risk as >= 4.5% risk. </p>
            <p>The other threshold, derived for research recruitment purposes, defined high risk as >= 9.5% risk. </p>
            <p>Thresholds may not be appropriate for a given patient or research subject, and the individual risks and benefits of categorizing a patient or research subject as high risk or not must be individually weighed.
        </p>
      </div>
      <br>
      <div id="discalimer">
        This website and calculator are intended for use by healthcare professionals. Physicians and other healthcare professionals who use this tool should exercise their own clinical judgment as to the information they provide to their patients. 
        It is not intended for use by consumers (non-medical professionals) to assess their own risks. These tools do not give professional advice. For medical concerns, including decisions about medications and other treatments, individuals should always consult their physician or other qualified healthcare professional. 
        While information on this site reflects the calculator mentioned in the journal Kidney Medicine under the title Acute Kidney Injury in the Outpatient Setting: Developing and Validating a Risk Prediction Model (DOI:10.1016/j.xkme.2021.08.011 PMID: 35072041), neither we nor our content providers warrant the accuracy of the information contained on this site. 
        The full article should be consulted for the context and limitations of this calculator prior to use. 
        Your use of the information and content from or through this site is solely at your own risk. Neither the University of Minnesota, the authors of the publication, nor our content providers assume any liability or responsibility for damage or injury arising from any use of information from this website.
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["final_calculation"],
  mounted: function (){

  var el = this.$el.getElementsByClassName("finalcalculation")[0];
    el.scrollIntoView({behavior: "smooth"});
},
updated: function(){

  var el = this.$el.getElementsByClassName("finalcalculation")[0];
  el.scrollIntoView({behavior: "smooth"});
}


};
</script>

<style>
#results {
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
  margin-top: 10px;
  padding: 20px;
  /* background-color: red; */
}

#final_calculation_value {
  font-size: 6.5em;
  font-weight:bolder
}
#discalimer {
  font-size: .75em;
}
#final_calculation {
  text-align: left;

}
</style>
