<template>
  <div>
    <form id="calculator" @submit.prevent="calculate">
      <div class="form-row">
        <div class="custom-group form-group col-md-4">
          <label for="age"> Age in Years </label>
          <b-input-group
            id="age-group"
            v-slot="{ ariaDescribedby }"
            class="mb-2 mr-sm-2 mb-sm-0 custom-input custom-input"
          >
            <b-form-input
              name="age"
              id="age"
              class="custom-input"
              v-model="getInput('age').value"
              type="number"
              step="any"
              :aria-describedby="ariaDescribedby"
              required
            />
            <b-form-invalid-feedback :state="stateAge">{{
              invalidAge
            }}</b-form-invalid-feedback>
            <b-form-valid-feedback :state="stateAge">{{
              validAge
            }}</b-form-valid-feedback>
            <div class="accepted-ranges">Acceptable Range 18-90 years old</div>
          </b-input-group>
        </div>
        <div class="col-md-2"></div>
        <div class="custom-group form-group col-md-6">
          <b-form-group label="Sex" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              name="sex-radio"
              id="sex"
              v-model="getInput('sex').value"
              :options="getInput('sex').options"
              :aria-describedby="ariaDescribedby"
              buttons
              required
            ></b-form-radio-group>
            <b-form-invalid-feedback :state="stateSex"
              >Please select one</b-form-invalid-feedback
            >
            <b-form-valid-feedback :state="stateSex"
              >Thank you</b-form-valid-feedback
            >
          </b-form-group>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-group form-group col-md-4">
          <label for="recent-creatinine"> Most Recent Serum Creatinine</label>
          <b-input-group
            id="recent-creatinine-group"
            append="mg/dL"
            class="mb-2 mr-sm-2 mb-sm-0 custom-input"
          >
            <b-form-input
              name="creatinine"
              id="recent-creatinine"
              class="custom-input"
              v-model="getInput('creatine').value"
              type="number"
              step="any"
              required
            />
          </b-input-group>
          <b-form-invalid-feedback :state="stateCreatinine">
            {{ invalidSerumCreatinine }}</b-form-invalid-feedback
          >
          <b-form-valid-feedback :state="stateCreatinine">{{
            validSerumCreatinine
          }}</b-form-valid-feedback>
          <div class="accepted-ranges">
            Acceptable Range >= 0.3 mg/dL and an eGFR >= 15 mL/min/1.73 m^2
          </div>
        </div>
        <div class="col-md-2"></div>
        <div class="custom-group form-group col-md-6">
          <b-form-group label="Race" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              name="race-radio"
              id="race"
              v-model="getInput('race').value"
              :options="getInput('race').options"
              :aria-describedby="ariaDescribedby"
              buttons
              required
            >
            </b-form-radio-group>
            <b-form-invalid-feedback :state="stateRace"
              >Please select one</b-form-invalid-feedback
            >
            <b-form-valid-feedback :state="stateRace"
              >Thank you</b-form-valid-feedback
            >
          </b-form-group>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-group form-group col-md-4">
          <b-form-group label="History of Smoking" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="smoking"
              v-model="getInput('smoking_history').value"
              :options="getInput('smoking_history').options"
              :aria-describedby="ariaDescribedby"
              name="smoking-radio"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="custom-group form-group col-md-4">
          <b-form-group
            label="History of Medical or Surgical Hospitalization"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="hospitalization"
              v-model="getInput('medical_surgical_history').value"
              :options="getInput('medical_surgical_history').options"
              :aria-describedby="ariaDescribedby"
              name="hospitalization-radio"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="custom-group form-group col-md-4">
          <b-form-group
            label="History of Liver Disease"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="liver"
              v-model="getInput('liver_disease').value"
              :options="getInput('liver_disease').options"
              :aria-describedby="ariaDescribedby"
              name="liver-radio"
              buttons
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="form-row"></div>
      <!-- <p>CHF, Prior MI, CAD, PVD, PAD, or Stroke</p> -->
      <div class="form-row">
        <div class="custom-group form-group col-md-4">
          <b-form-group
            label="History of Cardiovascular disease"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="cardiovascular"
              v-model="getInput('cardio_disease').value"
              :options="getInput('cardio_disease').options"
              :aria-describedby="ariaDescribedby"
              name="cardiovascular-radio"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="custom-group form-group col-md-4">
          <b-form-group
            label="History of Diabetes Melitus "
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="diabetes"
              v-model="getInput('diabetes_melitus').value"
              :options="getInput('diabetes_melitus').options"
              :aria-describedby="ariaDescribedby"
              name="diabetes-radio"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="custom-group form-group col-md-4">
          <b-form-group
            label="History of Diagnosed Hypertension"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="hypertension"
              v-model="getInput('diagnosed_hypertension').value"
              :options="getInput('diagnosed_hypertension').options"
              :aria-describedby="ariaDescribedby"
              name="hypertension-radio"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('serum_sodium')"
            label="Most Recent Serum Sodium"
            units="mmol/L"
          />
          <div class="accepted-ranges">Acceptable Range 110-155 mmol/L</div>
        </div>
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('serum_potassium')"
            label="Most Recent Serum Potassium"
            units="mmol/L"
          />
          <div class="accepted-ranges">Acceptable Range 2-7 mmol/L</div>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('serum_albumin')"
            label="Most Recent Serum Albumin"
            units="g/dL"
          />
          <div class="accepted-ranges">Acceptable Range 1-5.5 g/dL</div>
        </div>
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('hemoglobin')"
            label="Most Recent Hemoglobin"
            units="g/dL"
          />
          <div class="accepted-ranges">Acceptable Range 6-18 g/dL</div>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('hemoglobin_a1c')"
            label="Most Recent Hemoglobin A1C"
            units="%"
          />
          <div class="accepted-ranges">Acceptable Range 3-15.5 %</div>
        </div>
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('systolic_blood_pressure')"
            label="Most Recent Systolic Blood Pressure"
            units="mmHg"
          />
          <div class="accepted-ranges">Acceptable Range 70-210 mmHg</div>
        </div>
      </div>
      <div class="form-row">
        <div class="custom-group form-group col-md-6">
          <optional-input
            :input="getInput('diastolic_blood_pressure')"
            label="Most Recent Diastolic Blood Pressure"
            units="mmHg"
          />
          <div class="accepted-ranges">Acceptable Range 30-120 mmHg</div>
        </div>
        <div class="custom-group form-group col-md-6">
          <b-form-group
            label="Prior history of outpatient AKI (defined as >= 50% rise in creatinine in the outpatient setting and managed in the outpatient setting) "
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="outpatient_aki_history"
              v-model="getInput('outpatient_aki_history').value"
              :options="getInput('outpatient_aki_history').options"
              :aria-describedby="ariaDescribedby"
              name="outpatient_aki_history"
              buttons
            >
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <b-form-group label="Most recent measure of urine protein or albumin">
          <b-form-radio-group
            role="group"
            name="proteinuria"
            v-model="getInput('proteinuria').optional_coefficient"
            :options="getInput('proteinuria').options"
          />
        </b-form-group>
      </div>
      <div class="form-row">
        <div class="col-md-2"></div>
        <div class="col-md-4 text-right">
          <b-col lg="4" class="pb-2">
            <b-button
              size="lg"
              :disabled="isDisabled"
              type="submit"
              @submit="calculate"
              >Calculate</b-button
            >
          </b-col>
        </div>
        <div class="col-md-4 text-right custom-button">
          <b-col lg="4" class="pb-2">
            <b-button size="lg" @click="resetInput">Reset</b-button>
          </b-col>
        </div>
        <div class="col-md-2"></div>
      </div>
    </form>
    <results
      v-if="final_calculation !== null"
      :final_calculation="final_calculation"
    />
  </div>
</template>


<script>
import Results from "./Results.vue";
import OptionalInput from "./OptionalInput.vue";
import { inputs } from "../store/inputs.js";
export default {
  data() {
    return {
      // containes the percentage likelihood of an adeverse health event
      final_calculation: null,
      // this is the base value that all input results will be added to
      initial_value: 7.3838946,
      result: 0,
      // this is an array of all the potential inputs for the formula that determines the odds of adverse health event
      // each item in the array is a JSON object with the following fields
      // --> label = name of the input
      // --> input_type = html input type
      // --> value = the content submitted by the user
      // --> result = this is amount calculated after the each coefficient has been applied to the value field
      // --> coefficients = this is an array of objects containing each coefficient to be applied to the value field in order to calcuate the result field
      // ---> optional_coefficient = contains the number to be used for the input result if the input value is 0
      // to learn more about how coefficients are applied see the evaluateCoeffiecients method
      inputs: inputs,
    };
  },
  methods: {
    calculate() {
      // set calculated inputs
      this.calculateEgfr();
      this.calculateHospitalizationAge();
      this.calculateSexSystolicBP();
      this.calculateSexDiastolicBP();

      for (let input in this.inputs) {
        this.setInputResult(this.inputs[input].label);
      }

      this.result =
        this.initial_value +
        this.inputs
          .map((item) => item.result)
          .reduce((prev, curr) => prev + curr, 0);

      // not sure what value we ultimately get, but right now it is rounded to the nearest hundreth decimal
      //however, there seesm to be debate on stack overflow concerning the accuracy of using .toFixed()
      this.result = Math.pow(Math.E, this.result);
      this.result = (this.result / (this.result + 1)) * 100;

      this.final_calculation = this.result.toFixed(2);
    },
    setInputResult(label) {
      this.evaluateCoefficients(label);
    },
    getInput(value) {
      return this.inputs.find(({ label }) => label === value);
    },
    resetInput() {
      this.final_calculation = null;
      for (let i = 0; i < this.inputs.length; i++) {
        // worried that this MAY cause issues with type switching between null and integers
        this.inputs[i].value = null;
        this.inputs[i].result = null;
      }
      return null;
    },
    calculateEgfr() {
      let age = this.getInput("age").value;
      let sex = this.getInput("sex").value;
      let race = this.getInput("race").value;
      let creatine = this.getInput("creatine").value;
      let eGFRValue =
        175 * Math.pow(creatine, -1.154) * Math.pow(age, -0.203) * sex * race;
      let input = this.getInput("eGFR");
      input.value = eGFRValue;

      this.setInputResult("eGFR");
    },
    calculateHospitalizationAge() {
      let age = this.getInput("age").value;
      let hospitalization = this.getInput("medical_surgical_history").value;
      let input = this.getInput("hospitalization_age");
      let hospitalizationAge = age * hospitalization;
      input.value = hospitalizationAge;
    },
    calculateSexSystolicBP() {
      let sex = this.getInput("sex").value;
      let systolic_blood_pressure = this.getInput(
        "systolic_blood_pressure"
      ).value;
      let input = this.getInput("sex_systolic_blood_pressure");
      let sexSystolicBP = sex * systolic_blood_pressure;
      input.value = sexSystolicBP;
    },
    calculateSexDiastolicBP() {
      let sex = this.getInput("sex").value;
      let diastolic_blood_pressure = this.getInput(
        "diastolic_blood_pressure"
      ).value;
      let input = this.getInput("sex_diastolic_blood_pressure");
      let sexDiastolicBP = sex * diastolic_blood_pressure;
      input.value = sexDiastolicBP;
    },
    evaluateCoefficients(label) {
      var input = this.getInput(label);
      input.result = 0;

      if (input.value > 0) {
        for (const index in input.coefficients) {
          var coefficient = input.coefficients[index];
          // if input value minus the coefficient threshold is above 0
          // then subtract the coefficient threshold from the input value and multiply that by the coefficient value and evaluate to the power of the coefficient exponent
          // add this value to to the input's final result
          if (input.value - coefficient.threshold > 0) {
            // add the resulting value of a coeffecient evaluation to the inputs final result
            this.addResult(
              input,
              Math.pow(
                input.value - coefficient.threshold,
                coefficient.exponent
              ) * coefficient.value
            );
          }
        }
      }
      // if the input value is <= 0 then use the prefdefined optional coefficient if it has one
      else if (
        Object.prototype.hasOwnProperty.call(input, "optional_coefficient")
      ) {
        this.addResult(input, input.optional_coefficient);
      }
    },
    // add the resulting value of a coeffecient evaluation to the inputs final result
    addResult(input, value) {
      input.result = input.result + value;
    },
    validateSex() {
      if (this.getInput("sex").value === null) {
        return false;
      } else {
        return true;
      }
    },
    validateRace() {
      if (this.getInput("race").value == null) {
        return false;
      } else {
        return true;
      }
    },
    validateAge() {
      if (this.getInput("age").value === null) {
        this.invalidAge = "Please enter a value";
        return false;
      } else if (this.getInput("age").value < 1) {
        this.invalidAge = "Please enter a valid age";
        return false;
      } else if (this.getInput("age").value > 150) {
        this.invalidAge = "Please enter a valid age";
        return false;
      } else {
        this.validAge = "Thank you";
        return true;
      }
    },
    validateCreatinine() {
      if (
        this.getInput("creatine").value == null ||
        this.getInput("creatine").value == 0
      ) {
        this.invalidSerumCreatinine = "Please enter a value";
        return false;
      } else if (
        this.getInput("creatine").value > 0 &&
        this.getInput("creatine").value <= 0.3
      ) {
        this.validSerumCreatinine =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else {
        this.validSerumCreatinine = "Thank you";
        return true;
      }
    },
    validateSerumSodium() {
      if (
        this.getInput("serum_sodium").value == null ||
        this.getInput("serum_sodium").value == 0
      ) {
        this.invalidSerumSodium = "Acceptable Range 110-155 mmol/L";
        return false;
      } else if (
        this.getInput("serum_sodium").value > 0 &&
        this.getInput("serum_sodium").value < 110
      ) {
        this.validSerumSodium =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("serum_sodium").value > 155) {
        this.validSerumSodium =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validSerumSodium = "Thank you";
        return true;
      }
    },
    validateSerumPotassium() {
      if (
        this.getInput("serum_potassium").value == null ||
        this.getInput("serum_potassium").value == 0
      ) {
        this.invalidSerumPotassium = "Acceptable Range 2-7 mmol/L";
        return false;
      } else if (
        this.getInput("serum_potassium").value > 0 &&
        this.getInput("serum_potassium").value < 2
      ) {
        this.validSerumPotassium =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("serum_pot").value > 7) {
        this.validSerumPotassium =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validSerumPotassium = "Thank you";
        return true;
      }
    },
    validateSystolicBloodPressure() {
      if (
        this.getInput("systolic_blood_pressure").value == null ||
        this.getInput("systolic_blood_pressure").value == 0
      ) {
        this.invalidSystolicBloodPressure = "Acceptable Range 70-210 mmHg";
        return false;
      } else if (
        this.getInput("systolic_blood_pressure").value > 0 &&
        this.getInput("systolic_blood_pressure").value < 70
      ) {
        this.validSystolicBloodPressure =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("systolic_blood_pressure").value > 210) {
        this.validSystolicBloodPressure =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validSystolicBloodPressure = "Thank you";
        return true;
      }
    },
    validateDiastolicBloodPressure() {
      if (
        this.getInput("diastolic_blood_pressure").value == null ||
        this.getInput("diastolic_blood_pressure").value == 0
      ) {
        this.invalidDiastolicBloodPressure = "Acceptable Range 30-120 mmHg";
        return false;
      } else if (
        this.getInput("diastolic_blood_pressure").value > 0 &&
        this.getInput("diastolic_blood_pressure").value < 30
      ) {
        this.validDiastolicBloodPressure =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("diastolic_blood_pressure").value > 120) {
        this.validDiastolicBloodPressure =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validDiastolicBloodPressure = "Thank you";
        return true;
      }
    },
    validateHemoglobin() {
      if (
        this.getInput("hemoglobin").value == null ||
        this.getInput("hemoglobin").value == 0
      ) {
        this.invalidRecentHemoglobin = "Acceptable Range 6-18 g/dL";
        return false;
      } else if (
        this.getInput("hemoglobin").value > 0 &&
        this.getInput("hemoglobin").value < 6
      ) {
        this.validRecentHemoglobin =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("hemoglobin").value > 18) {
        this.validRecentHemoglobin =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validRecentHemoglobin = "Thank you";
        return true;
      }
    },
    validateSerumAlbumin() {
      if (
        this.getInput("serum_albumin").value == null ||
        this.getInput("serum_albumin").value == 0
      ) {
        this.invalidSerumAlbumin = "Acceptable Range 1-5.5 g/dL";
        return false;
      } else if (
        this.getInput("serum_albumin").value > 0 &&
        this.getInput("serum_albumin").value < 1
      ) {
        this.validSerumAlbumin =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("serum_albumin").value > 5.5) {
        this.validSerumAlbumin =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validSerumAlbumin = "Thank you";
        return true;
      }
    },
    validateHemoglobinA1c() {
      if (
        this.getInput("hemoglobin_a1c").value == null ||
        this.getInput("hemoglobin_a1c").value == 0
      ) {
        this.invalidRecentHemoglobinA1C = "Acceptable Range 3-15.5 %";
        return false;
      } else if (
        this.getInput("hemoglobin_a1c").value > 0 &&
        this.getInput("hemoglobin_a1c").value < 3
      ) {
        this.validRecentHemoglobinA1C =
          "Value entered is lower than acceptable range, but can still be used.";
        return true;
      } else if (this.getInput("hemoglobin_a1c").value > 15.5) {
        this.validRecentHemoglobinA1C =
          "Value entered is higher than acceptable range, but can still be used.";
        return true;
      } else {
        this.validRecentHemoglobinA1C = "Thank you";
        return true;
      }
    },
    validateInputs() {
      let sex = this.validateSex();
      let race = this.validateRace();
      let creatinine = this.validateCreatinine();
      let age = this.validateAge();

      if (sex && race && creatinine && age == true) {
        return true;
      }
    },
  },
  components: {
    Results,
    OptionalInput,
  },
  computed: {
    stateSex() {
      return this.validateSex(this.getInput("sex").value);
    },
    stateRace() {
      return this.validateRace(this.getInput("race").value);
    },
    stateAge() {
      return this.validateAge(this.getInput("age").value);
    },
    stateCreatinine() {
      return this.validateCreatinine(this.getInput("creatine").value);
    },
    stateSerumSodium() {
      return this.validateSerumSodium(this.getInput("serum_sodium").value);
    },
    stateSerumPotassium() {
      return this.validateSerumPotassium(
        this.getInput("serum_potassium").value
      );
    },
    stateSystolicBloodPressure() {
      return this.validateSystolicBloodPressure(
        this.getInput("systolic_blood_pressure").value
      );
    },
    stateSerumAlbumin() {
      return this.validateSerumAlbumin(this.getInput("serum_albumin").value);
    },
    stateRecentHemoglobin() {
      return this.validateHemoglobin(this.getInput("hemoglobin").value);
    },
    stateRecentHemoglobinA1C() {
      return this.validateHemoglobinA1c(this.getInput("hemoglobin_a1c").value);
    },
    stateDiastolicBloodPressure() {
      return this.validateDiastolicBloodPressure(
        this.getInput("diastolic_blood_pressure").value
      );
    },
    isDisabled() {
      return !this.validateInputs();
    },
  },
};
</script>

<style>
form {
  /* max-width: 420px; */
  /* margin: 30px auto; */
  /* background: white; */
  text-align: left;
  padding: 40px;
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.6);
  /* border-radius: 10px; */
}

label {
  color: black;
  display: inline-block;
  size: 1.3em;
  /* font-weight: bold; */
}

.custom-input {
  border-radius: 10px;
  width: 75%;
}

.custom-button {
  width: 75%;
}

.btn.active {
  background-color: #555555;
}

.btn {
  background-color: #e7e7e7;
  color: black;
  text-align: center;
}

.input-group-append {
  height: 62%;
}

.accepted-ranges {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.valid-feedback {
  color: #18632e;
}
</style>
