<template>
  <div id="app" class="container">
    <header class="umnhf" id="umnhf-h" role="banner">
      <div class="printer">
        <div class="left"></div>
        <div class="right">
          <strong>University of Minnesota</strong
          ><br />http://twin-cities.umn.edu/<br />612-625-5000
        </div>
      </div>
      <div class="umnhf" id="umnhf-h-mast">
        <a class="umnhf" id="umnhf-h-logo" href="http://twin-cities.umn.edu/"
          ><span>Go to the U of M home page</span></a
        >
        <ul class="umnhf" id="umnhf-h-ql">
          <li><a href="http://onestop.umn.edu/">One Stop</a></li>
          <li class="umnhf">
            <a href="https://www.myu.umn.edu/"
              >MyU <span></span>: For Students, Faculty, and Staff</a
            >
          </li>
        </ul>
        <!-- Button below is for dropdown toggle, only visible on mobile screens. If using
                      a non-dropdown version you can delete this tag -->
        <!-- <button class="umnhf" id="umnhf-m-search">Search</button> -->
      </div>
      <!-- Twin Cities Campus CSE Code -->
      <div class="umnhf" id="umnhf-h-search" role="search">
        <!-- <script>
          (function() {
          var cx = '002834015805923805805:c-0k--9bdkk';
          var gcse = document.createElement('script');
          gcse.type = 'text/javascript';
          gcse.async = true;
          gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(gcse, s);
          })();
      </script> -->
        <!-- <gcse:searchbox-only></gcse:searchbox-only> -->
      </div>
    </header>
    <instructions />
    <calculator />
    <footer id="umnhf-f" class="umnhf" role="contentinfo">
      <nav id="umnhf-f-myu">
        <h2 class="umnhf-f-title visually-hidden">
          For Students, Faculty, and Staff
        </h2>
        <ul>
          <li><a href="http://onestop.umn.edu/">One Stop</a></li>
          <li>
            <a href="https://www.myu.umn.edu/">My U <span></span></a>
          </li>
        </ul>
      </nav>
      <small
        >&copy; <span id="cdate">2022</span> Regents of the University of
        Minnesota. All rights reserved. The University of Minnesota is an equal
        opportunity educator and employer.
        <a href="http://privacy.umn.edu">Privacy Statement</a></small
      >
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import "bootstrap/dist/css/bootstrap.css";
import Instructions from "./components/Instructions.vue";
import Calculator from "./components/Calculator.vue";

export default Vue.extend({
  components: {
    Instructions,
    Calculator,
  },
});
</script>

<style>
/* .container {
  background-color: beige;
} */
</style>
