import Vue from 'vue'
import App from './App.vue'
// style imports
import './assets/css/vue-modified-2015-tc.css'
import { BootstrapVue } from 'bootstrap-vue'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
new Vue({
  render: function (h) { return h(App) }
}).$mount('#app')
