<!-- this component is used for inputs that may or may not be included in the calculation of adverse health event -->
<!-- users must indicate that the value for this input has been measured prior to being allowed to specify a value -->
<!-- this is achieved by using a radio input to control whether or not the target input is disabled-->

<template>
  <b-form-group
    :fieldset="input.label + '-radio'"
    :label="label"
    v-slot="{ ariaDescribedby }"
  >
    <fieldset>
      <legend>
        <b-form-radio-group
          role="group"
          legend="recent measurement?"
          :label="label + ' available?'"
          @change="resetInputValue(input)"
          v-model="visibility"
          :options="radio_options"
          :aria-describedby="ariaDescribedby"
          buttons
        >
        </b-form-radio-group>
        <b-input-group
          :label="label"
          :append="units"
          class="mb-2 mr-sm-2 mb-sm-0 custom-input cmp-style"
        >
          <b-form-input
            :aria-label="label + ' value'"
            :id="input.name"
            :append="units"
            class="custom-input"
            v-model="input.value"
            :type="input.input_type"
            :disabled="!visibility"
            @input="$parent.setInputResult(input.label)"
            step="any"
          />
        </b-input-group>
      </legend>
    </fieldset>
  </b-form-group>
</template>


<script>
export default {
  data() {
    return {
      visibility: false,
      radio_options: [
        {
          fieldset: "",
          text: "Available",
          value: true,
        },
        {
          fieldset: "",
          text: "Never Measured",
          value: false,
        },
      ],
    };
  },
  methods: {
    resetInputValue(input) {
      console.log(input);
      if (!this.visibility) {
        input.value = "0";
      }
    },
  },
  props: ["label", "units", "input"],
};
</script>

<style scoped>
.cmp-style {
  margin-top: 10px;
}
</style>
