// this is an array of all the potential inputs for the formula that determines the odds of adverse health event
// each item in the array is a JSON object with the following fields
// --> label = name of the input
// --> input_type = html input type
// --> value = the content submitted by the user
// --> result = this is amount calculated after the each coefficient has been applied to the value field
// --> coefficients = this is an array of objects containing each coefficient to be applied to the value field in order to calcuate the result field
// ---> optional_coefficient = contains the number to be used for the input result if the input value is 0
// to learn more about how coefficients are applied see the evaluateCoeffiecients method
export const inputs = [{
    // age of the patient xx.xxxxx in years for example 66.60632
    label: "age",
    required: true,
    input_type: "number",
    value: null,
    result: 0,
    optional_coefficient: 0,
    coefficients: [{
        threshold: 0,
        value: -0.0024745162,
        exponent: 1,
      },
      {
        threshold: 26.936345,
        value: 0.000015239198,
        exponent: 3,
      },
      {
        threshold: 48.510609,
        value: -0.000066141745,
        exponent: 3,
      },
      {
        threshold: 61.798768,
        value: 0.00006805147,
        exponent: 3,
      },
      {
        threshold: 82.069815,
        value: -0.000017148923,
        exponent: 3,
      },
    ],
  },
  {
    label: "sex",
    required: true,
    input_type: "select",
    value: null,
    result: 0,
    options: [
      {
        text: "Female",
        value: 0,
      },
      {
        text: "Male",
        value: 1,
      }
    ],
    coefficients: [{
      threshold: 0,
      value: -0.049698466,
      exponent: 1,
    }, ],
  },
  {
    label: "race",
    required: true,
    input_type: "select",
    value: null,
    result: 0,
    options: [
        {
            text: "Black",
            value: 1.212,
        },
        {
            text: "Other",
            value: 1,
        }
    ]
  },
  {
    label: "creatine",
    required: true,
    input_type: "number",
    value: null,
    result: 0,
  },
  {
    label: "smoking_history",
    input_type: "select",
    value: 0,
    result: 0,
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    coefficients: [{
      threshold: 0,
      value: 0.20340742,
      exponent: 1,
    }, ],
  },
  {
    label: "outpatient_aki_history",
    input_type: "select",
    value: 0,
    result: 0,
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    coefficients: [{
      threshold: 0,
      value: 0.77286506,
      exponent: 1,
    }, ],
  },
  {
    label: "medical_surgical_history",
    input_type: "select",
    value: 0,
    result: 0,
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    coefficients: [{
      threshold: 0,
      value: -0.40815593,
      exponent: 1,
    }, ],
  },
  {
    label: "cardio_disease",
    input_type: "select",
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    value: 0,
    result: 0,
    coefficients: [{
      threshold: 0,
      value: 0.25436026,
      exponent: 1,
    }, ],
  },
  {
    label: "diabetes_melitus",
    input_type: "select",
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    value: 0,
    result: 0,
    coefficients: [{
      threshold: 0,
      value: 0.29516303,
      exponent: 1,
    }, ],
  },
  {
    label: "diagnosed_hypertension",
    input_type: "select",
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    value: 0,
    result: 0,
    coefficients: [{
      threshold: 0,
      value: 0.09536429,
      exponent: 1,
    }, ],
  },
  {
    label: "liver_disease",
    input_type: "select",
    options: [
        {
            text: "Yes",
            value: 1,
        },
        {
            text: "No",
            value: 0,
        }
    ],
    value: 0,
    result: 0,
    coefficients: [{
      threshold: 0,
      value: 0.22483995,
      exponent: 1,
    }, ],
  },
  {
    label: "serum_sodium",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: -5.8451221,
    coefficients: [{
        threshold: 0,
        value: -0.040820407,
        exponent: 1,
      },
      {
        threshold: 132,
        value: 0.00013470555,
        exponent: 3,
      },
      {
        threshold: 139,
        value: 0.0020677934,
        exponent: 3,
      },
      {
        threshold: 141,
        value: -0.0039851445,
        exponent: 3,
      },
      {
        threshold: 144,
        value: 0.0017826456,
        exponent: 3,
      },
    ],
  },
  {
    label: "serum_potassium",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: -1.3293186,
    coefficients: [{
        threshold: 0,
        value: -0.44249033,
        exponent: 1,
      },
      {
        threshold: 3.4,
        value: 0.24128697,
        exponent: 3,
      },
      {
        threshold: 4,
        value: -0.19704294,
        exponent: 3,
      },
      {
        threshold: 4.3,
        value: -0.3603348,
        exponent: 3,
      },
      {
        threshold: 4.8,
        value: 0.31609077,
        exponent: 3,
      },
    ],
  },
  {
    label: "hemoglobin_a1c",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: 0.49895313,
    coefficients: [{
      threshold: 0,
      value: 0.07890113,
      exponent: 1,
    }, ],
  },
  {
    label: "serum_albumin",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: 0.28250627,
    coefficients: [{
        threshold: 0,
        value: 0.47285011,
        exponent: 1,
      },
      {
        threshold: 0,
        value: -0.02787098,
        exponent: 3,
      },
      {
        threshold: 3.6,
        value: 0.93347182,
        exponent: 3,
      },
      {
        threshold: 4.1,
        value: -1.4930423,
        exponent: 3,
      },
      {
        threshold: 4.7,
        value: 0.58744148,
        exponent: 3,
      },
    ],
  },
  {
    label: "hemoglobin",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: -1.777211,
    coefficients: [{
      threshold: 0,
      value: -0.12295046,
      exponent: 1,
    }, ],
  },
  {
    label: "systolic_blood_pressure",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: -0.63935315,
    coefficients: [{
        threshold: 0,
        value: -0.0019426203,
        exponent: 1,
      },
      {
        threshold: 92,
        value: 0.00000023298665,
        exponent: 3,
      },
      {
        threshold: 118,
        value: 0.0000063585393,
        exponent: 3,
      },
      {
        threshold: 130,
        value: -0.000010139691,
        exponent: 3,
      },
      {
        threshold: 154,
        value: 0.0000035481652,
        exponent: 3,
      },
    ],
  },
  {
    label: "diastolic_blood_pressure",
    input_type: "number",
    value: 0,
    result: 0,
    optional_coefficient: -1.0947418,
    coefficients: [{
        threshold: 0,
        value: -0.028767533,
        exponent: 1,
      },
      {
        threshold: 52,
        value: 0.000028895006,
        exponent: 3,
      },
      {
        threshold: 70,
        value: -0.000094968138,
        exponent: 3,
      },
      {
        threshold: 80,
        value: 0.000077791565,
        exponent: 3,
      },
      {
        threshold: 92,
        value: -0.0000117,
        exponent: 3,
      },
    ],
  },
  {
    label: "proteinuria",
    input_type: "radio",
    value: 0,
    result: 0,
    optional_coefficient: 0.0446272,
    options:
      [
        {
          text: 'Never been measured.',
          value: 0.0446272,
        },
        {
          text: 'UACR was < 30 mg/g; or UPCR was < 0.03 g/g; or UA protein or albumin was < "30"; or UA protein or albumin was "negative".',
          value: 0
        },
        {
          text: 'UPCR was >= 0.03 but less than0.3 g/g" to " UPCR was >= 0.03 but < 0.3 g/g;',
          value: 0.12370627
        },
        {
          text: 'UACR was >= 300 mg/g; or UPCR was >= 0.3 g/g ;or UA protein or albumin was >= "300"; or UA protein or albumin was "2+".',
          value: 0.422004997
        }
        ]

  },

  // the following inputs are computed
  {
    label: "eGFR",
    type: "hidden",
    value: 0,
    result: 0,
    coefficients: [{
        threshold: 0,
        value: -0.022430616 ,
        exponent: 1,
      },
      {
        threshold: 46.677272,
        value: 0.0000097338977,
        exponent: 3,
      },
      {
        threshold: 70.912146,
        value: -0.000021165259,
        exponent: 3,
      },
      {
        threshold: 85.877661,
        value: 0.0000096192266,
        exponent: 3,
      },
      {
        threshold: 121.64961,
        value: 0.0000018121343,
        exponent: 3,
      },
    ],
  },
  {
    label: "hospitalization_age",
    type: "hidden",
    value: 0,
    result: 0,
    coefficients: [{
        threshold: 0,
        value: 0.020788863,
        exponent: 1,
      },
      {
        threshold: 26.936345,
        value: -0.00001597087,
        exponent: 3,
      },
      {
        threshold: 48.510609,
        value: 0.000037726513,
        exponent: 3,
      },
      {
        threshold: 61.798768,
        value: -0.00001901936,
        exponent: 3,
      },
      {
        threshold: 82.069815,
        value: 0.0000027362829,
        exponent: 3,
      },
    ],
  },
  {
    label: "sex_systolic_blood_pressure",
    type: "hidden",
    value: 0,
    result: 0,
    coefficients: [{
          threshold: 0,
          value: -0.014641716,
          exponent: 1,
        },
        {
          threshold: 92,
          value: 0.0000063253808,
          exponent: 3,
        },
        {
          threshold: 118,
          value: -0.000017829665,
          exponent: 3,
        },
        {
          threshold: 130,
          value: 0.000010403931,
          exponent: 3,
        },
        {
          threshold: 154,
          value: 0.0000011003536,
          exponent: 3,
        },
      ],
  },
  {
    label: "sex_diastolic_blood_pressure",
    type: "hidden",
    value: 0,
    result: 0,
    coefficients: [{
          threshold: 0,
          value: 0.025062257,
          exponent: 1,
        },
        {
          threshold: 52,
          value: -0.000038679698,
          exponent: 3,
        },
        {
          threshold: 70,
          value: 0.0001724996,
          exponent: 3,
        },
        {
          threshold: 80,
          value: -0.00018731694,
          exponent: 3,
        },
        {
          threshold: 92,
          value: 0.000053497038,
          exponent: 3,
        },
      ],
  }
]
